main {
	min-height: 80vh;
}

.rating span {
	margin: 0.1rem;
}

.rating svg {
	color: #f8e825;
}

.rating-text {
	font-size: 0.8rem;
	font-weight: 600;
	padding-left: 0.5rem;
}

.product-title {
	height: 2.5em; /* Set a fixed height */
	overflow: hidden; /* Hide overflow content */
	text-overflow: ellipsis; /* Add ellipsis for long text */
	white-space: nowrap; /* Prevent wrapping */
}

table td,
table th {
	text-align: center;
}

.review {
	margin-top: 30px;
}

.review h2 {
	font-size: 24px;
	background: #f4f4f4;
	padding: 10px;
	border: 1px solid #ddd;
}

.review button {
	margin-top: 10px;
}

.carousel-caption {
	position: absolute;
	width: 100%;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.5);
}

/*-----------------------------------------------
|   Remove spinner from Input[type="number"]
-----------------------------------------------*/
.input-spin-none::-webkit-outer-spin-button,
.input-spin-none::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.input-spin-none[type='number'] {
	appearance: none;
}

.wizard-lottie-wrapper {
	height: 1px;
	position: relative;
	padding-bottom: 11.875rem;
	overflow: hidden;
	.wizard-lottie {
		width: 21.25rem;
		height: auto;
		transform: translateY(-73%);
	}
}

.search-input {
	padding-left: 2rem;
	padding-right: 2rem;
	line-height: 1.7;
	border-radius: 50rem;
	box-shadow: none;

	&::-webkit-search-cancel-button {
		display: none;
	}
}

.search-dropdown-row {
	padding: 0.2rem;
	display: flex;
	justify-content: space-between;
	border: 0.2px solid;
	font-size: small;
}

.search-dropdown-row:hover {
	background-color: #3c4c5d;
	color: #f4f4f4;
}

.input-search-width {
	width: 12.8rem;
}

.kanban-item-dropdown-btn {
	padding: 0 5;
	position: absolute !important;
	right: 0;
	top: 0;
	z-index: 2 !important;
}

.dropdown-caret-none::after {
	display: none !important;
}

.dropdown-caret-none::before {
	display: none !important;
}

/*---------- Dashboard ------------*/

/* .dashboard-button {
	height: 126px;
	background: #e29b38;
	border-radius: 10px;
	margin-bottom: 10px;
}

.dashboard-button:hover {
	height: 126px;
	background: #eead52;
	border-radius: 10px;
	margin-bottom: 10px;
	cursor: pointer;
}

.dashboard-button:nth-of-type(1) {
	background-image: url('./../img/svg/add-plus-svgrepo-com.svg');
	background-repeat: no-repeat;
	background-position: top 22px left 19px;
}

.dashboard-button:nth-of-type(2) {
	background-image: url('./../img/svg/search-magnifying-glass-svgrepo-com.svg');
	background-repeat: no-repeat;
	background-position: top 30px left 30px;
}

.dashboard-button:nth-of-type(3) {
	background-image: url('./../img/svg/settings-svgrepo-com.svg');
	background-repeat: no-repeat;
	background-position: top 30px left 30px;
}

.dashboard-button-title {
	color: #fff;
	line-height: 1.7;
	padding-left: 115px;
	padding-top: 36px;
	font-size: 30px;
} */
